@import '../components/Input/input';
@import '../components/Button/button';

.react-bs-table-tool-bar{

  .form-control{
    margin: 0 5px;
  }

  input{
    @extend .shared-input-styles;
  }

  .btn{
    @extend .artomos-button;
  }
}

.react-bs-table-container.selectable-rows{
  tr{
    cursor: pointer;
  }
}

