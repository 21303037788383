@import '../scss/utils_theme.scss';
@import '../scss/utils_flex.scss';


// Global Items
a:hover{
  text-decoration: none;
}

*:focus{
  outline: none !important;
}

*{
  user-select: none;
}


//Header
.app-header .navbar-brand{
  width: 200px;
  background: $artomos-theme-color-sidebar;
}

.breadcrumb{
  background: transparent;
  border: none;
}

// Pagination
.card .pagination {
  justify-content: flex-end;
  margin-top: $artomos-theme-unit * 2;
  margin-bottom: 0;
}

// Sidebar
.sidebar {
  background: $artomos-theme-color-sidebar;

  .nav-link {
    &:hover {
      background: rgba(0, 0, 0, .15)
    }

    &.active {
      background: rgba(255,255,255,.1);
    }
    &.active .nav-icon{
      color: white
    }
  }

  .nav-dropdown-toggle::before{
    transform: rotateZ(90deg)
  }

}

// Loader
.loader-container{
  @extend .flex-col;
  @extend .flex-center;
  height: 300px;
}

// Footer
.app-footer{
  background: transparent;
  border: none;
  font-size: $artomos-theme-font-size-x-small;
}
