@import '../../scss/utils_flex.scss';
@import '../../scss/utils_theme.scss';

.shared-input-styles {
  height: $artomos-theme-input-height !important;
  width: 100%;
  padding: $artomos-theme-unit;
  border: $artomos-theme-border-width solid $artomos-theme-border-color;
  color: $artomos-theme-font-color-text;
  border-radius: $artomos-theme-border-radius !important;
  font-size: $artomos-theme-font-size-med !important;
  @extend .flex-row;
}

.artomos-input {
  margin-bottom: $artomos-theme-unit * 1.5;

  &.disabled {
    opacity: 0.65;
  }

  .input-field {
    @extend .shared-input-styles
  }

  .input-field.has-error{
    border-color: $artomos-theme-font-color-error;
    background-color: #fff0f2;
  }

  .label-container {
    @extend .flex-row;
    justify-content: space-between;
    margin-bottom: $artomos-theme-unit * 0.20;
  }

  .input-label {
    margin-bottom: $artomos-theme-unit * 0.3;
    font-weight: bold;
    font-size: $artomos-theme-font-size-small;
    color: $artomos-theme-font-color-text;
  }

  .input-label-error {
    @extend .input-label;
    color: $artomos-theme-font-color-error;
    font-weight: normal;
    text-align: right;
  }

  .error-text {
    color: $artomos-theme-font-color-error;
  }
}





