@import '../../scss/utils_flex.scss';
@import '../../scss/utils_theme.scss';

.artomos-button, .btn {
  @extend .flex-row;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  margin-bottom: 15px;
  max-height: $artomos-theme-button-height;
  min-height: $artomos-theme-button-height;
  height: $artomos-theme-button-height;

  // Button Types

  &.primary {
    background-color: $artomos-theme-button-bg-color-primary;
    color: $artomos-theme-button-text-color-primary;
    border-color: transparent;
    border-style: solid;
  }

  &.secondary {
    background-color: $artomos-theme-button-bg-color-secondary;
    color: $artomos-theme-button-text-color-secondary;
    border-width: $artomos-theme-border-width;
    border-color: $artomos-theme-button-text-color-secondary;
    border-style: solid;
  }

  &.tertiary {
    font-size: $artomos-theme-font-size-med;
    background-color: $artomos-theme-button-bg-color-tertiary;
    color: $artomos-theme-button-text-color-tertiary;
    max-height: $artomos-theme-unit * 2;
    min-height: $artomos-theme-unit * 2;
    height: $artomos-theme-unit * 2;
    border: none;
    font-weight: normal;
  }

  &.quaternary {
    background-color: $artomos-theme-button-bg-color-quaternary;
    color: $artomos-theme-button-text-color-quaternary;
  }

  &.quinary {
    color: $artomos-theme-button-text-color-quinary;
    background-color: $artomos-theme-button-bg-color-quinary;
    border-width: $artomos-theme-border-radius;
    border-color: $artomos-theme-font-color-white;
    border-style: solid;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.full-width {
    width: 100%;
  }

  &:hover{
    cursor: pointer;
  }

  .textContainer {
    @extend .flex-row;
    align-self: stretch;
    align-items: center;
    justify-content: center;
  }

  .spinner-container {
    margin: 0;
  }

  .spinner-border.text-primary {
    color: white !important;
    opacity: 0.5;
  }
}




