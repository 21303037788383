
.flex-col{
  display: flex;
  flex-direction: column;
}

.flex-row{
  display: flex;
  flex-direction: row;
}

.flex-center{
  justify-content: center;
  align-items: center;
}
