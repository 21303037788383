
// Colors
$artomos-theme-color-primary: #283277;
$artomos-theme-color-hover: rgba(0,0,0,0.05);


// Font Colors
$artomos-theme-font-color-white: #fff;
$artomos-theme-font-color-link: #0076ff;
$artomos-theme-font-color-text: #4a4a4a;
$artomos-theme-font-color-success: #46C019;
$artomos-theme-font-color-warning: #e8a600;
$artomos-theme-font-color-error: #d0011b;
$artomos-theme-font-color-black: #000;

// Backgrounds
$artomos-theme-color-bg-light-gray: #fafafa;

// Font Size
$artomos-theme-font-size-x-small: 10px;
$artomos-theme-font-size-small: 12px;
$artomos-theme-font-size-med: 14px;
$artomos-theme-font-size-large: 18px;

// Border
$artomos-theme-border-radius: 2px;
$artomos-theme-border-width: 1px;
$artomos-theme-border-color: #d0d0d0;

// Units
$artomos-theme-unit: 12px;

// Component: Sidebar
$artomos-theme-color-sidebar: #000c62;

// Input
$artomos-theme-input-height: 40px;

// Button
$artomos-theme-button-height: 40px;

// Button: Primary
$artomos-theme-button-text-color-primary: #fff;
$artomos-theme-button-bg-color-primary: #039be5;

// Button: Secondary
$artomos-theme-button-text-color-secondary: #039be5;
$artomos-theme-button-bg-color-secondary: transparent;

// Button: Tertiary
$artomos-theme-button-text-color-tertiary: $artomos-theme-font-color-link;
$artomos-theme-button-bg-color-tertiary: transparent;

// Button: Quaternary
$artomos-theme-button-text-color-quaternary: #283277;
$artomos-theme-button-bg-color-quaternary: #fff;

// Button: Quinary
$artomos-theme-button-text-color-quinary: #fff;
$artomos-theme-button-bg-color-quinary: transparent;

// Animations
$artomos-theme-transition: ease-in-out .35s;
